import actions from 'rdp-builder-components/lib/actions';

import UserEvents from '~/events/user';
import HistoryEvents from '~/events/history';
import InteractionsEvents from '~/events/interactions';
import EntityEvents from '~/events/entity';
import ReviewEvents from '~/events/review';

const events = {
  [actions.REGISTER_CONTENT]: UserEvents.registerContent,
  [actions.REGISTER_USER]: UserEvents.register,
  [actions.LOGIN_USER]: UserEvents.signin,
  [actions.SILENT_LOGIN_USER]: UserEvents.silentSignIn,
  [actions.REDIRECT_TO]: HistoryEvents.redirect,
  [actions.RECOVERY_PASSWORD]: UserEvents.recoveryUserPassword,
  [actions.RECOVERY_USER_PASSWORD]: UserEvents.recoveryUserPassword,
  [actions.LOGOUT]: UserEvents.logout,
  [actions.CHANGE_PASSWORD]: UserEvents.changePassword,
  [actions.CHANGE_LOGGED_PASSWORD]: UserEvents.changeLoggedPassword,
  [actions.CHANGE_USER_PASSWORD]: UserEvents.changeUserPassword,
  [actions.GET_CONTENT]: InteractionsEvents.getContent,
  [actions.GET_ACTIVITY_STATUS]: InteractionsEvents.getActivityStatus,
  [actions.REGISTER_INTERACTION]: InteractionsEvents.register,
  [actions.REGISTER_INTERACTION_DURATION]: InteractionsEvents.duration,
  [actions.ENTITY_DETAILS]: EntityEvents.details,
  [actions.TOGGLE_REVIEW_FLOWS]: UserEvents.toggleReviewFlows,
  [actions.AVAILABLE_TO_REVIEW]: UserEvents.availableToReview,
  [actions.OPEN_REVIEW]: UserEvents.openReview,
  [actions.ACCEPT_REVIEW]: UserEvents.acceptReview,
  [actions.REJECT_REVIEW]: UserEvents.rejectReview,
  [actions.REVIEW_GET_CODES]: ReviewEvents.getCodes,
  [actions.CODE_REVIEW_SET_COMMENTS]: ReviewEvents.codeReviewSetCodes,
  [actions.SAVE_REVIEW]: ReviewEvents.saveReview,
  [actions.SUBMIT_REVIEW]: ReviewEvents.submitReview,
  [actions.REVIEWER_SEND_FILES]: ReviewEvents.sendFile,
  [actions.BUY_PRODUCT]: InteractionsEvents.buyProduct,
  [actions.GET_CEP_INFO]: InteractionsEvents.getCepInfo,
  [actions.UPDATE_USER]: UserEvents.updateUser,
  [actions.SILENT_REGISTER]: UserEvents.silentRegister,
  [actions.READ_NOTIFICATIONS]: UserEvents.readNotifications,
  [actions.SAVE_FAVORITE]: UserEvents.saveFavorite,
  [actions.REMOVE_FAVORITE]: UserEvents.removeFavorite,
  [actions.GET_ACTIVITY_FAVORITES]: UserEvents.getActivityFavorites,
  [actions.GET_USER_ENTITIES]: EntityEvents.getSchemaEntities,
  [actions.GET_ACTIVITY_FORUM_MESSAGES]: UserEvents.getActivityForumMessages,
  [actions.SEND_HELP_DESK_Z_TICKET]: UserEvents.sendHelpDeskZTicket,
  [actions.GET_CLASS_RANK]: UserEvents.getRank,
  [actions.EDIT_BILLBOARD_NOTIFICATION]: UserEvents.updateBillboardNotification,
  [actions.DELETE_BILLBOARD_NOTIFICATION]: UserEvents.deleteBillboardNotification,
  [actions.CREATE_BILLBOARD_NOTIFICATION]: UserEvents.createBillboardNotification,
  [actions.GET_BILLBOARD_NOTIFICATION]: UserEvents.getBillboardNotifications,
  [actions.PERSONAL_INFORMATION_LOGIN_CONFIRM]: UserEvents.personalInformationLoginConfirm,
  [actions.PERSONAL_INFORMATION_QUESTION_CONFIRM]: UserEvents.personalInformationQuestionConfirm,
  [actions.PERSONAL_INFORMATION_NEW_PASSWORD_CONFIRM]: UserEvents.personalInformationNewPasswordConfirm,
  [actions.TERMS_POLICIES_ACCEPT]: UserEvents.answerTermsPolicies,
  [actions.TERMS_POLICIES_DECLINE]: UserEvents.answerTermsPolicies,
  [actions.COURSE_CATALOG_VIEW_CLICK]: UserEvents.registerIntoOffer,
  [actions.CONTEXT_CHECK]: UserEvents.userContextCheck,
  [actions.CONFIRM_CONTEXT]: UserEvents.setContext,
  [actions.OPEN_LINK]: InteractionsEvents.openLink,
  [actions.GET_COURSES_BY_CATEGORY]: EntityEvents.getCoursesFromCategory,
}

function dispatch({
  action,
  payload,
  onChangeState = () => {},
  executeRecaptcha = () => {},
  setConfig = () => {},
}, context) {

  try{
    return events[action]({ payload, onChangeState, executeRecaptcha, setConfig }, context);
  }catch(e)
  {
    console.log(e);
    console.log("erro", events);
    console.log("erro-action", action);
  }
}

export { dispatch };
