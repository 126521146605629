import Sdk from 'api.digitalpages.module.sdk.api';

async function details({ payload, onChangeState }) {
  onChangeState({
    loading: true
  });

  const response = await Sdk.dynamic.bridge(`auth/v1.0/entity/uid/${payload.entity}`);

  onChangeState({
    loading: false,
    response
  });
}
async function getSchemaEntities ({ payload, onChangeState }) {
  function mountFilters () {
    return new Promise(async (resolve, reject) => {
      try {
        const categorySchemas = await Sdk.dynamic.bridge('auth/v1.0/entity/schema/available?type=category')
        const filters = []
        if (!categorySchemas) {
          onChangeState({ success: false });
          return resolve([]);
        }
        for (const category of categorySchemas) {
          const schemaItems = await Sdk.dynamic.bridge(`auth/v1.0/entity/schema/uid/${category.uid}/items`)
          filters.push({
            uid: category.uid,
            label: category.name,
            children: schemaItems.map(item => ({
              uid: item.uid
            }))
          })
        }
        resolve(filters);
      } catch (error) {
        console.error("Error fetching schema entities:", error);
        onChangeState({ success: false });
        resolve([]);
      }
    })
  };



  const filters = await mountFilters()

  return onChangeState({
    success: true,
    filters
  })
}

async function getCoursesFromCategory({ payload, onChangeState }) {
  const cards = [];
  const req = await Sdk.dynamic.bridge(`cms/v1.1/learning/courses?page_size=800`);
  const courses = req.result;

  const selectedCategoryUid = payload;

  courses
    .filter(c => c.status.toLowerCase() !== "blocked")
    .forEach(element => {
      const hasSelectedCategory = element.course.entities.some(entity => entity == selectedCategoryUid);

      if (hasSelectedCategory) {
        cards.push({
          "uid": element.course.uid,
          "registerUid": element.register.uid,
          "register": element.register,
          "title": element.course.title,
          "description": element.course.description,
          "thumb_url": element.course.thumb_url,
          "entities": element.course.entities,
          "progress": (() => {
            if (element.activities && element.activities.total_activities) {
              let progress = parseInt(((element.activities.total_status_completed + element.activities.total_status_passed) / element.activities.total_activities) * 100);
              return isFinite(progress) ? progress : 0;
            }
            return 0;
          })(),
          "status": (() => {
            if (element.activities && element.activities.total_activities && element.activities.total_status_not_attempted != null) {
              return element.activities.total_status_not_attempted == element.activities.total_activities ? 'not_started' : (element.activities.total_activities == (element.activities.total_status_completed + element.activities.total_status_passed)) ? 'finished' : 'in_progress';
            }
            return 'not_started';
          })(),
          "paymentStatus": element.status,
          "reviews": element.review_tasks && element.review_tasks.length > 0 ? ([element.review_tasks[element.review_tasks.length - 1]].filter(p => p.status == 'Failed').map(p => (p.flow = element.review_flows.find(f => f.uid == p.review_flow_uid), { 'title': p.flow.title, 'description': p.flow.description, 'status': p.status, 'route': `/player/curso/${element.course.uid}/atividade/${p.target_uid}` }))) : [],
          "first_project_approved": (() => {
            if (element.activities && element.activities.total_status_completed != null && element.activities.total_status_passed != null) {
              return (element.activities.total_status_completed + element.activities.total_status_passed) === 1;
            }
            return false;
          })(),
        });
      }
    });

  onChangeState({
    success: true,
    cards
  });
}


export default {
  getCoursesFromCategory,
  details,
  getSchemaEntities
}