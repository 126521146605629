import { createGlobalStyle } from 'styled-components';

import InterRegular from './assets/fonts/Inter-Regular.ttf';
import InterBold from './assets/fonts/Inter-Bold.ttf';
import PromptThin from "./assets/fonts/Prompt-Thin.ttf";
import PromptRegular from './assets/fonts/Prompt-Regular.ttf';
import PromptMedium from "./assets/fonts/Prompt-Medium.ttf";
import PromptSemibold from "./assets/fonts/Prompt-Semibold.ttf";
import PromptBold from './assets/fonts/Prompt-Bold.ttf';
import RobotoThin from "./assets/fonts/Roboto-Thin.ttf";
import RobotoRegular from './assets/fonts/Roboto-Regular.ttf';
import RobotoMedium from "./assets/fonts/Roboto-Medium.ttf";
import RobotoBold from './assets/fonts/Roboto-Bold.ttf';
import RobotoBlack from './assets/fonts/Roboto-Black.ttf';
 
export default createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  // font: inherit;
  vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
  display: block;
  }
  body {
  line-height: 1;
  }
  ol, ul {
  list-style: none;
  }
  blockquote, q {
  quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
  content: '';
  content: none;
  }
  table {
  border-collapse: collapse;
  border-spacing: 0;
  }


  /*CLEARFIX PARA AJUSTAR O FLOAT*/

  .clearfix:after {
  content: "."; /* Injeta conteudo via CSS para a pagina HTML do meu site */
  display: block; /* Para que o elemento ocupe uma linha inteira */
  clear: both; /* Quebra qualquer flutuação que exista */
  visibility: hidden;
  line-height: 0;
  height: 0;
  }

  .clearfix {
  display: block;
  }

  html[xmlns] .clearfix {
  display: block;
  }

  * html .clearfix {
  height: 1%;
  }

  /* RESOLVER PADDING - http://www.paulirish.com/2012/box-sizing-border-box-ftw */
  /* apply a natural box layout model to all elements */
  *, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  }

  @font-face {
  font-family: Inter;
  src: url('${InterRegular}') format("truetype");
  }

  @font-face {
  font-family: Inter-Bold;
  src: url('${InterBold}') format("truetype");
  }

  @font-face {
    font-family: Prompt-Thin;
    src: url('${PromptThin}') format("truetype");
  }

  @font-face {
    font-family: Prompt;
    src: url('${PromptRegular}') format("truetype");
  }

  @font-face {
    font-family: Prompt-Medium;
    src: url('${PromptMedium}') format("truetype");
  }

  @font-face {
    font-family: Prompt-Semibold;
    src: url('${PromptSemibold}') format("truetype");
  }

  @font-face {
    font-family: Prompt;
    font-weight: bold;
    src: url('${PromptBold}') format("truetype");
  }

  @font-face {
    font-family: Roboto-Thin;
    src: url('${RobotoThin}') format("truetype");
  }

  @font-face {
    font-family: Roboto-Regular;
    src: url('${RobotoRegular}') format("truetype");
  }

  @font-face {
    font-family: Roboto-Medium;
    src: url('${RobotoMedium}') format("truetype");
  }

  @font-face {
    font-family: Roboto-Semibold;
    src: url('${RobotoBold}') format("truetype");
  }

  @font-face {
    font-family: Roboto-Bold;
    src: url('${RobotoBlack}') format("truetype");
  }

  html {
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
  }

  * {
    box-sizing: border-box;
    font-family: Prompt;
  }

  b {
    font-family: Prompt;
    font-weight: bold;
  }

  .font-thin {
    font-family: Prompt-Thin!important;
  }

  .font-medium {
    font-family: Prompt-Medium!important;
  }

  .font-semibold {
    font-family: Prompt-Semibold!important;
  }

  body.roboto b {
    font-family: Roboto-Bold;
  }

  body.roboto * {
    font-family: Roboto-Regular;
  }

  body.roboto.font-thin {
    font-family: Roboto-Thin!important;
  }

  body.font-medium {
    font-family: Roboto-Medium!important;
  }

  body.font-semibold {
    font-family: Roboto-Semibold!important;
  }

  input[type='date'], input[type='time'] {
    -webkit-appearance: none;
  }

  .rdp-component-tree-card-title {
    color: #494949!important;
  }

  .rdp-component-input-text-input {
    color: #000000!important;
  }

  .rdp-component-default-header-logo {
    max-height: 75px!important;
  }

  .rdp-component-login-input-text {
    color: #636363!important;
  }
`;