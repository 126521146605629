import Sdk from 'api.digitalpages.module.sdk.api';
import * as yup from 'yup';
import preferences from '../preferences.json';
import jwt_decode from "jwt-decode";
import jwt_encode from "jwt-encode";
import {getConfigByProject} from "~/services/config.js";

async function register({payload, onChangeState}, context) {
  const schema = yup.object().shape({
    name: yup.string().required(),
    lastName: yup.string().required(),
    // login: yup.string().email().required(),
    login: yup.string().required(),
    password: yup.string().required(),
    repassword: yup.string()
      .required()
      .oneOf([yup.ref('password'), null]),
    // terms: yup.string().required()
  });

  // var customization = Sdk.authorization.activeProject.connectors.find(connector => connector.type === "Customization_1");
  console.log(localStorage.getItem("rdp-app-config"));

  const getLogin = () => {
    var tempLogin = "email";

    var config = localStorage.getItem("rdp-app-config");
    if (config) {
      var configStructure = JSON.parse(localStorage.getItem("rdp-app-config")).config.config;
      if (configStructure.register && configStructure.register.username_property_to_register) {
        tempLogin = configStructure.register.username_property_to_register;
      }
    }

    return tempLogin;
  }

  

  const {
    name: first_name,
    lastName: last_name,
    login: email_principal,
    cpf,
    birthday,
    region,
    password
  } = payload || {};

  var loginProperty = getLogin() === "cpf" ? cpf.replaceAll(".", "").replaceAll("-", "") : email_principal;

  const body = {
    detail: {
      name: `${first_name} ${last_name}`,
      email_principal,
      first_name,
      last_name,
      cpf,
      birthday,
      region
    },
    login: loginProperty,
    password
  };

  let url = new URL(window.location.origin + "?" + (window.location.href.split("?")[1] || ""))
  let subscribe = url.searchParams.get("subscribe");
  let multiplesubscribe = url.searchParams.get("multiple-subscribe");
  let initialMessage = url.searchParams.get("initialMessage");
  let entitiesParam = url.searchParams.get("groups");
  let entities = entitiesParam ? entitiesParam.split(",") : [];

  if (entities.length > 0) {
    body.entities = entities;
  }

  body.preferences = [];


  if (subscribe) {
    body.preferences.push({
      key: "course_subscribe",
      value: subscribe
    });
  } else if (multiplesubscribe) {
    body.preferences.push({
      key: "course_subscribe",
      value: multiplesubscribe
    });
  }

  if (initialMessage) {
    var message = JSON.parse(window.atob(initialMessage));
    body.preferences.push({
      key: "initial_message",
      value: JSON.stringify({
        "message": message.message,
        "action": {
          "type": "link",
          "target": "_blank",
          "url": message.url
        }
      })
    })
  }

  onChangeState({
    loading: true
  });

  const messages = {
    oneOf: {
      repassword: 'As senhas não coincidem'
    },
    required: {
      terms: 'É necessário concordar com os Termos de Uso e Política de privacidade',
      defaultMessage: 'É necessário preencher todos os campos'
    },
    email: {
      defaultMessage: 'O e-mail inserido é inválido.'
    }
  }

  try {
    await schema.validate(payload);

    if (Sdk.authorization.activeProject) {
      const directRegisterConnector = Sdk.authorization.activeProject.connectors.find(connector => connector.type === "DirectRegister_1");
      if (directRegisterConnector !== undefined) {
        // A versão 1.1 suporta o envio de entidades a versão 1.0 suporta o auto cadastro nas ofertas
        let version = (body.entities || []).length ? "v1.1" : "v1.0"

        const response = await fetch(`${Sdk.Api.domain}/auth/${version}/register/connector/uid/${directRegisterConnector.uid}/direct`, {
          "headers": {
            "api-env": Sdk.Api.enviroment,
            "project-key": Sdk.Api.projectKey,
          },
          "method": "POST",
          "body": JSON.stringify(body)
        });
        if (response.status === 400) {
          onChangeState({
            loading: false,
            error: 'O e-mail inserido já está sendo utilizado.'
          });
        } else {
          onChangeState({
            loading: false,
            success: true,
          });
          var preferences = window.RDP_PREFERENCES;
          window.dataLayer.push({'ecommerce': undefined});
          window.dataLayer.push({
            'event': "clickEvent",
            'eventCategory': `${(preferences.google_gtm && preferences.google_gtm.client) ? preferences.google_gtm.client : "digital-pages"}:funil-de-compra`,
            'eventAction': "botao:click",
            'eventLabel': "cadastro-realizado",
            'eventCallback': () => {
              signin({payload: {login: loginProperty, password}, onChangeState}, context);
            }
          });
        }
      } else {
        onChangeState({
          loading: false,
          error: 'Conector de registro não encontrado'
        });
      }
    }
  } catch (e) {
    if (messages[e.type]) {
      const error = messages[e.type][e.path] || messages[e.type]['defaultMessage'];
      return onChangeState({
        error,
        loading: false
      });
    }

    onChangeState({
      error: 'É necessário preencher todos os campos.',
      loading: false
    })
  }
}

async function silentRegister({payload, onChangeState}, context) {
  const schema = yup.object().shape({
    name: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    emailConfirmation: yup.string().email().oneOf([yup.ref('email')]),
    password: yup.string().required(),
    passwordConfirmation: yup.string().required().oneOf([yup.ref('password')]),
    terms: yup.boolean().required(),
    phone_principal: yup.string().required()
  });
  const messages = {
    oneOf: {
      passwordConfirmation: 'As senhas não coincidem'
    },
    required: {
      terms: 'É necessário concordar com os Termos de Uso e Política de privacidade',
      defaultMessage: 'É necessário preencher todos os campos'
    },
    email: {
      defaultMessage: 'O e-mail inserido é inválido.'
    }
  }
  try {
    // detail: {
    //   name: `${first_name} ${last_name}`,
    //   email_principal,
    //   first_name,
    //   last_name,
    //   cpf,
    //   birthday,
    //   region
    // },
    // login,
    // password

    await schema.validate(payload);
    const {
      name: first_name,
      lastName: last_name,
      email: email_principal,
      cpf,
      phone_principal,
      birthday,
      region,
      login,
      password
    } = payload || {};

    const body = {
      detail: {
        name: `${first_name} ${last_name}`,
        email_principal,
        first_name,
        last_name,
        cpf,
        birthday,
        region,
        phone_principal
      },
      login,
      password
    };
    const response = await Sdk.Api.directRegister.register(body);
    if (response.status === 400) {
      return onChangeState({
        loading: false,
        success: false,
        error: 'O e-mail inserido já está sendo utilizado.'
      });
    }
    return onChangeState({
      success: true,
      response
    });

  } catch (e) {
    if (messages[e.type]) {
      const error = messages[e.type][e.path] || messages[e.type]['defaultMessage'];
      return onChangeState({
        error,
        loading: false,
        success: false
      });
    } else {
      return onChangeState({
        loading: false,
        success: false,
        error: 'Algo deu errado...'
      });
    }
  }
}

async function silentSignIn({payload, onChangeState}, context) {
  const {login, password} = payload;
  const schema = yup.object().shape({
    // login: yup.string().email().required(),
    login: yup.string().required(),
    password: yup.string().required(),
  });
  try {
    await schema.validate(payload);
    let userInfo;
    Sdk.Api.authorization.signinByLogin(login, password).then(function (response) {
      if (response.json) {
        response = response.json()
      } else {
        if (Sdk.Api.authorization.user !== null) {
          userInfo = Sdk.Api.authorization.user.detail
        }
        return onChangeState({
          loading: false,
          success: true,
          response: userInfo
        })
      }
    }, function (data) {
      const currentLang = window.localStorage.getItem(`rdp-active-language-${window.location.origin}`);
      if (data.status === 401) {
        onChangeState({loading: false, success: false, error: currentLang === 'pt-br' ? 'As credenciais informadas são inválidas.' : 'The credentials entered are invalid'});
      }

      if (data.status === 400) {
        return data.json()
      }
    }).then(function (data) {
      if (data) onChangeState({loading: false, success: false, error: data.detail});
    });

    // .then(response => {
    //   if (response.json) {
    //     return response.json()
    //   }
    //   return onChangeState({ loading: false, success: true });
    // }).then(res => {
    //     return onChangeState({ loading: false, success: true });
    // })
  } catch (err) {
    const currentLang = window.localStorage.getItem(`rdp-active-language-${window.location.origin}`);
    if (err.name === 'ValidationError') {
      return onChangeState({loading: false, success: false, error: currentLang === "pt-br" ? 'Digite seu e-mail e sua senha.' : 'Enter your email and password.'});
    }
  }
}

async function signin({payload, onChangeState}, context) {
  let {login =  "", password, loginType} = payload || {};
  const schema = yup.object().shape({
    // login: yup.string().email().required(),
    login: yup.string().required(),
    password: yup.string().required(),
  });

  const removeMask = (value) => {
    return value.replace(/\D+/g, '');
  }

  if ((typeof loginType === "object" && loginType.includes("cpf")) || loginType === "cpf" ||
    (typeof loginType === "object" && loginType.includes("cnpj")) || loginType === "cnpj") {
    payload.login = login = removeMask(login);
  }

  const cpfMaskPattern = '999.999.999-99';
  const cnpjMaskPattern = '99.999.999/9999-99';

  const loginMatchCPFPattern = login.match('^' + cpfMaskPattern.replace(/9/g, '\\d') + '$');
  const loginMatchCNPJPattern = login.match('^' + cnpjMaskPattern.replace(/9/g, '\\d') + '$');
  const loginMatchLength = removeMask(login).length === 11 || removeMask(login).length === 14;

  if (loginType === "text" && loginMatchLength && (loginMatchCPFPattern || loginMatchCNPJPattern)) {
    payload.login = removeMask(login);
  }

  try {
    await schema.validate(payload);

    onChangeState({loading: true});

    localStorage.setItem('rdp-app-credentials', JSON.stringify({login, password, loginType, projectUid: Sdk.authorization.activeProjectUid}));

    Sdk.Api.authorization.signinByLogin(login, password).then(function (response) {
      if (response.json) {
        return response.json();
      } else {
        const secret = 'secret';
        var usrObj = jwt_decode(response.accessToken);
        var oauthObj = {
          "id": usrObj.oid,
          "username": login,
          "email": usrObj.email
        }

        const jwt = jwt_encode(oauthObj, secret);

        const d = new Date();
        d.setTime(d.getTime() + (2000 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        var domain = window.location.host;
        var subdomain = window.location.hostname.split('.').slice(0, -2).join('.');
        if (subdomain.split(".").length > 1) {
          domain = window.location.host.replace(subdomain, subdomain.split(".")[1]);
        }
        ;

        document.cookie = "token" + "=" + jwt + ";" + expires + ";domain=" + domain + ";path=/";

        const redirectToHome = () => {
          onChangeState({loading: false, success: true});
          // var roles = Sdk.Api.authorization.user.roles;
          var isReviewer = false;
          // for (var i = 0; i < roles.length; i++) {
          //   if (roles[i].type === "ReviewAdmin") {
          //     isReviewer = true;
          //   }
          // };

          if (isReviewer === true) {
            context.history.push('/submissoes')
          } else {
            // if (window.localStorage.getItem(window.location.origin + "RDP_FORCE_REDIRECT") !== null) {
            //   var redirect = window.localStorage.getItem(window.location.origin + "RDP_FORCE_REDIRECT");
            //   window.localStorage.removeItem(window.location.origin + "RDP_FORCE_REDIRECT");
            //   if (redirect === "#/submissoes") {
            //     window.location = window.location.origin + window.location.pathname + "#/inicio";
            //   } else {
            //     window.location = window.location.origin + window.location.pathname + redirect;
            //   };
            // } else {
            //   context.history.push('/inicio');
            // };
            //context.history.push('/catalogo');
            window.location = window.location.origin + window.location.pathname + "#/catalogo";
            console.log(window.location)
          }
          ;
        }

        redirectToHome();
      }
    }, function (data) {
      const currentLang = window.localStorage.getItem(`rdp-active-language-${window.location.origin}`);
      if (data.status === 401) {
        onChangeState({loading: false, success: false, error: currentLang === 'pt-br' ? 'As credenciais informadas são inválidas.' : 'The credentials entered are invalid'});
      }

      if (data.status === 400) {
        return data.json()
      }
    }).then(function (data) {
      if (data) onChangeState({loading: false, success: false, error: data.detail});
    });
  } catch (err) {
    const currentLang = window.localStorage.getItem(`rdp-active-language-${window.location.origin}`);
    if (err.name === 'ValidationError') {
      return onChangeState({loading: false, success: false, error: currentLang === "pt-br" ? 'Digite seu e-mail e sua senha.' : 'Enter your email and password.'});
    }
  }

  // onChangeState({ loading: false, success: true });

  // var roles = Sdk.Api.authorization.user.roles;
  // var isReviewer = false;
  // for (var i = 0; i < roles.length; i++) {
  //   if (roles[i].type === "ReviewAdmin") {
  //     isReviewer = true;
  //   }
  // };


  // if (isReviewer === true) {
  //   context.history.push('/submissoes')
  // } else {
  //   context.history.push('/inicio')
  // }

  // } catch (err) {

  //   if (err.name === 'ValidationError') {
  //     return onChangeState({ loading: false, success: false, error: 'Digite seu e-mail e sua senha.' });
  //   }
  //   onChangeState({ loading: false, success: false, error: 'As credenciais informadas são inválidas.' });
  // }
}

function checkPendingSubscriptions(cb) {
  try {
    const getSubscriptions = () => {
      let storage = window.localStorage.getItem(window.location.origin + "RDP_COURSE_SUBSCRIBE");
      if (storage && storage !== "") {
        return storage.split(",");
      }
      return [];
    }
    if (getSubscriptions().length > 0 && Sdk.authorization && Sdk.authorization.user) {
      let courseRegisterUidsArr = getSubscriptions();
      let promises = courseRegisterUidsArr.map(async registerUid => {
        return new Promise(async (resolve, reject) => {
          try {
            await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${registerUid}`, null, "POST");
            resolve(registerUid);
          } catch (err) {
            if (err.status !== 500) {
              resolve(registerUid);
            }
          }
        });
      });

      Promise.all(promises).then(() => {
        window.localStorage.setItem(window.location.origin + "RDP_COURSE_SUBSCRIBE_DONE", "true");
        window.location.reload();
      })
    }
  } catch (err) {
    console.error(err);
  }
}

async function logout({onChangeState}, context) {
  try {
    onChangeState({loading: true});
    // await Sdk.Api.authorization.logout();
    Sdk.Api.authorization.logout();
    // TODO: CORRIGIR SDK E REMOVER TIMEOUT
    setTimeout(function() {
      window.localStorage.removeItem(window.location.origin + "RDP_FORCE_REDIRECT");
      window.localStorage.removeItem("rdp-app-credentials");
      window.dispatchEvent(new Event("@rdpplayer.event(logout)"));
      onChangeState({loading: false, success: true});

      // if (window.cordova || window.location.hostname === "localhost") {
      if (window.cordova) {
        context.history.push("/mobile-login")
      } else {
        context.history.push("/")
      }
    }, 500)
   
  } catch (err) {
    onChangeState({loading: false, success: false, error: true});
  }
}

async function recoveryPassword({payload, onChangeState}, context) {
  try {
    const {email} = payload || {};

    const schema = yup.object().shape({
      email: yup.string().email().required(),
    });

    const messages = {
      required: 'É necessário preencher o e-mail',
      email: 'O e-mail inserido é inválido.'
    }

    try {
      await schema.validate(payload);
    } catch (e) {
      return onChangeState({error: messages[e.type]});
    }

    onChangeState({loading: true});

    var connectorUid = null;
    var project = Sdk.authorization.activeProject;
    var connectors = project.connectors;

    for (var i = 0; i < connectors.length; i++) {
      if (connectors[i].type === "DirectRegister_1") {
        connectorUid = connectors[i].uid;
      }
    }

    await Sdk.dynamic.bridge(`auth/v1.0/user/connector/uid/${connectorUid}/password/forgot`, {
      "email": email
    }, "POST");

    onChangeState({loading: false, success: true});
  } catch (err) {
    if (err.status === 400) {
      return onChangeState({
        loading: false,
        error: 'Este e-mail não está cadastrado.'
      });
    }

    onChangeState({loading: false, success: false, error: true});
  }
}

async function recoveryUserPassword({payload, onChangeState}, context) {
  try {
    const {email} = payload || {};

    const schema = yup.object().shape({
      email: yup.string().email().required(),
    });

    const messages = {
      required: 'É necessário preencher o e-mail',
      email: 'O e-mail inserido é inválido.'
    }

    try {
      await schema.validate(payload);
    } catch (e) {
      return onChangeState({error: messages[e.type]});
    }

    onChangeState({loading: true});

    const {
      activeProject = {}
    } = Sdk.authorization;
    const {
      directory = {}
    } = activeProject;

    const directoryUid = directory.uid || null;

    if (directoryUid) {
      await Sdk.dynamic.bridge(`auth/v1.0/user/password/forgot/directory/uid/${directoryUid}?email=${email}`, null, "POST");
    } else {
      await Sdk.dynamic.bridge(`auth/v1.0/user/password/forgot?email=${email}`, null, "POST");
    }

    onChangeState({loading: false, success: true});
  } catch (err) {
    if (err.status === 400) {
      return onChangeState({
        loading: false,
        error: 'Este e-mail não está cadastrado.'
      });
    }

    onChangeState({loading: false, success: false, error: true});
  }
}

async function changePassword({payload, onChangeState}, context) {
  const schema = yup.object().shape({
    token: yup.string().required(),
    password: yup.string().required(),
    repassword: yup.string()
      .required()
      .oneOf([yup.ref('password'), null], null)
  });

  const messages = {
    required: {
      token: 'É necessário especificar o token.',
      defaultMessage: 'É necessário preencher todos os campos.'
    },
    oneOf: {
      repassword: 'As senhas não coincidem.'
    },
  }

  try {
    await schema.validate(payload);

    const {token, password} = payload || {};

    onChangeState({loading: true});

    // await Sdk.Api.directRegister.resetPassword({ token, password });
    var directRegisterConnector = Sdk.authorization.activeProject.connectors.find(connector => connector.type === "DirectRegister_1");
    if (directRegisterConnector !== undefined) {
      await Sdk.dynamic.bridge(`auth/v1.0/user/connector/uid/${directRegisterConnector.uid}/password/reset`, {
        "token": token,
        "password": password
      }, "POST");
    }
    ;

    onChangeState({loading: false, success: true});
  } catch (e) {
    if (messages[e.type]) {
      const error = messages[e.type][e.path] || messages[e.type]['defaultMessage'];
      return onChangeState({
        error,
        loading: false
      });
    }
  }
}

async function changeUserPassword({payload, onChangeState}, context) {
  const schema = yup.object().shape({
    token: yup.string().required(),
    password: yup.string().required(),
    repassword: yup.string()
      .required()
      .oneOf([yup.ref('password'), null], null)
  });

  const messages = {
    required: {
      token: 'É necessário especificar o token.',
      defaultMessage: 'É necessário preencher todos os campos.'
    },
    oneOf: {
      repassword: 'As senhas não coincidem.'
    },
  }

  try {
    await schema.validate(payload);

    const {token, password} = payload || {};

    onChangeState({loading: true});

    await Sdk.dynamic.bridge(`auth/v1.0/user/reset/password/token`, {token, password}, "POST");

    onChangeState({loading: false, success: true});
  } catch (e) {
    if (messages[e.type]) {
      const error = messages[e.type][e.path] || messages[e.type]['defaultMessage'];
      return onChangeState({
        error,
        loading: false
      });
    }
  }
}

async function changeLoggedPassword({payload, onChangeState}, context) {
  const schema = yup.object().shape({
    password: yup.string().required(),
    repassword: yup.string()
      .required()
      .oneOf([yup.ref('password'), null], null)
  });

  const messages = {
    required: {
      defaultMessage: "It is necessary to fill in all fields."
    },
    oneOf: {
      repassword: "Passwords do not match."
    },
  }

  try {
    await schema.validate(payload);

    const {password} = payload || {};

    onChangeState({loading: true});

    await Sdk.dynamic.bridge(`auth/v1.0/user/reset/password`, {password}, "POST");

    onChangeState({loading: false, success: true});
  } catch (e) {
    if (messages[e.type]) {
      const error = messages[e.type][e.path] || messages[e.type]['defaultMessage'];
      return onChangeState({
        error,
        loading: false
      });
    }
  }
}

async function updateUser({payload, onChangeState}) {
  const response = await Sdk.dynamic.bridge('auth/v1.0/user/me', payload, 'PUT')
  return onChangeState({success: true, response: response})
}

async function toggleReviewFlows({payload, onChangeState}) {
  const {active} = payload;

  const flows = await Sdk.dynamic.bridge(`cms/v1.0/review/flows/`);

  var action = active ? "register" : "unregister";
  for (var i = 0; i < flows.length; i++) {
    await Sdk.dynamic.bridge(`cms/v1.0/review/flow/uid/${flows[i].uid}/${action}/queue`, null, 'PUT');
  }
  ;

  return flows;
}

async function availableToReview({payload, onChangeState}) {
  const flows = await Sdk.dynamic.bridge(`cms/v1.0/review/flows/registered/queue`);
  return (flows.length > 0);
}

async function openReview({payload}, context) {
  return {"success": true};
}

async function acceptReview({payload, onChangeState}) {
  const {uid} = payload;
  // await Sdk.dynamic.bridge(`cms/v1.0/review/task/uid/${uid}/start`);
  await Sdk.dynamic.bridge(`cms/v1.0/review/task/uid/${uid}/start`, null, 'PUT');
  return {"success": true};
}

async function rejectReview({payload, onChangeState}) {
  const {uid} = payload;
  // await Sdk.dynamic.bridge(`cms/v1.0/review/task/uid/${uid}/cancel`);
  await Sdk.dynamic.bridge(`cms/v1.0/review/task/uid/${uid}/cancel`, null, 'PUT');
  return {"success": true};
}

async function readNotifications({payload, onChangeState}) {
  const {uids} = payload;

  for (var i = 0; i < uids.length; i++) {
    await Sdk.dynamic.bridge(`cms/v1.0/user/data/uid/${uids[i]}`, {
      "last_viewed_at": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString()
    }, 'PUT');
  }

  return {"success": true};
}

async function saveFavorite({payload, onChangeState}) {
  // var connectorUid = Sdk.Api.authorization.activeProject.connectors.find(connector => connector.type === 'UserData_1').uid;
  var connectorUid = Sdk.Api.authorization.projects[0].connectors.find(connector => connector.type === 'UserData_1').uid;
  var favorite = await Sdk.dynamic.bridge(`cms/v1.0/user/connector/uid/${connectorUid}/data`, payload, 'POST');
  onChangeState({loading: false, success: true, data: favorite});
}

async function removeFavorite({payload, onChangeState}) {
  const {uid} = payload;

  await Sdk.dynamic.bridge(`cms/v1.0/user/data/uid/${uid}`, null, 'DELETE');
  onChangeState({loading: false, success: true});
}

async function getActivityFavorites({payload, onChangeState}) {
  var fav = await Sdk.dynamic.bridge(`cms/v1.0/user/data?target_uid=${payload.activityUid}`, null, 'GET');
  var model = fav.result.length > 0 ? fav.result[0] : null;
  onChangeState({loading: false, success: true, data: model});
}

async function getActivityForumMessages({payload, onChangeState}) {
  var messages = [{
    "author": "Por: Simone Costa - Sábado, 27 Nov 2021",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    "is_owner": true,
    "children": [{
      "author": "Por: Mohamad Saad - Sábado, 28 Nov 2021",
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
      "is_owner": false,
      "children": [{
        "author": "Por: Leonardo Ribeiro - Sábado, 29 Nov 2021",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        "is_owner": false,
        "children": []
      }]
    }]
  }, {
    "author": "Por: Simone Costa - Sábado, 01 Dez 2021",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    "is_owner": false,
    "children": [{
      "author": "Por: Mohamad Saad - Sábado, 02 Dez 2021",
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
      "is_owner": false,
      "children": [{
        "author": "Por: Leonardo Ribeiro - Sábado, 03 Dez 2021",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        "is_owner": true,
        "children": []
      }]
    }]
  }]

  onChangeState({loading: false, success: true, data: messages})
}

async function registerContent({payload, onChangeState}) {
  onChangeState({loading: true});

  const {uid} = payload;

  const registers = await Sdk.dynamic.bridge(`cms/v1.0/learning/course/uid/${uid}/registers`);
  const registerCourse = await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${registers.result[0].uid}`, null, "POST");

  const response = {
    "contentUid": registerCourse.course.uid,
    "registerUid": registerCourse.register.uid
  };

  onChangeState({
    loading: false,
    response
  });
}

async function registerIntoOffer({payload, onChangeState}, context) {
  onChangeState({loading: true});

  const {metadata: {uid}} = payload;

  try {
    const response = await fetch(`${Sdk.Api.domain}/cms/v1.0/learning/register/uid/${uid}`, {
      method: 'POST',
      headers: {
        "Project-Key": Sdk.Api.projectKey,
        "Api-Env": Sdk.Api.enviroment,
        "Authorization": `Bearer ${Sdk.Api.authorization.credential.accessToken}`,
        "Content-Type": "application/json"
      },
    });


    if (response.ok) {
      const data = await response.json();

      context.history.push(`/curso/${data.course.uid}/registro/${data.register.uid}`);
    }
  } catch (e) {
    console.error(e);
    onChangeState({
      loading: false,
      success: false
    });
    return;
  }

  onChangeState({
    loading: false,
    success: true
  });
}

async function sendHelpDeskZTicket({payload, onChangeState}) {
  console.log(payload)

  // const body = new FormData();
  // body.append("opener", "Aluno Teste")
  // body.append("department_id", "2")
  // body.append("subject", payload.subject)
  // body.append("body", payload.message)

  fetch(`https://goopi.com.br/helpdeskz/api/tickets/create/`, {
    method: 'POST',
    body: {
      "opener": "Aluno Teste",
      "department_id": "2",
      "subject": payload.subject,
      "body": payload.message,
    },
    headers: {
      "Token": "iCPMNnSYHEvWLkARzl0xmFy2Xc3q56ZegDQ7OIB4sTGf9uJ8V1pUrjwbKath"
    }
  }).then(res => res.json()).then(function (data) {
    console.log(data)
  })

  // fetch('https://goopi.com.br/helpdeskz/api/tickets/create', {
  //   body: body,
  //   method: 'POST',
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     "Token": "iCPMNnSYHEvWLkARzl0xmFy2Xc3q56ZegDQ7OIB4sTGf9uJ8V1pUrjwbKath"
  //   }
  // }).then(res => res.json()).then(function(data) {
  //   console.log(data)
  // })

  onChangeState({loading: false, success: true, data: {}})
}

async function getRank({payload, onChangeState}) {
  const {uid} = payload;
  let rank = {};

  try {
    let resp = await fetch(`${Sdk.domain}/cms/v1.0/learning/course/uid/${uid}/report/ranking`, {
      method: "GET",
      headers: {
        "Project-Key": Sdk.Api.projectKey,
        "Api-Env": Sdk.Api.enviroment,
        "Authorization": `Bearer ${Sdk.Api.authorization.credential.accessToken}`
      }
    });
    rank = await resp.json();
  } catch (error) {
    console.log(error);
  }

  onChangeState({
    data: {
      result: rank.ranking_users,
    },
    current_user: rank.current_user
  });
}

async function createBillboardNotification({payload, onChangeState}) {
  const {data, context_uid} = payload;

  try {

    let users = await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${context_uid}/report/scores/reviewer?page_size=500000`);

    const users_uid = users.result.map(item => item.user_uid);

    let body = {
      "context_type": "CourseRegister",
      "context_uid": context_uid,
      "target_type": "CourseRegister",
      "target_uid": context_uid,
      "project_uid": Sdk.authorization.activeProject.uid,
      "action_type": "Custom",
      "type": "Notification",
      "template": `{\"title\":\"${data.title}\",\"description\":\"${data.content}\",\"annotation\":\"\"}`,
      "users_uids": users_uid
    }

    await Sdk.dynamic.bridge(`communication/v1.0/send/message/management`, body, "POST");
  } catch (e) {
    console.error(e);
  }

  onChangeState({
    data: [],
  });
}

async function updateBillboardNotification({payload, onChangeState}) {
  const {uid, data} = payload;

  let body = {
    "uid": uid,
    "name": data.title,
    "description": data.content,
  }

  let response = {};

  try {
    response = await Sdk.dynamic.bridge(`cms/v1.0/user/data/uid/${uid}`, body, "PUT");
  } catch (e) {
    console.error(e);
  }

  onChangeState({
    data: {
      "uid": uid,
      "title": response.name,
      "content": response.description,
    }
  });
}

async function deleteBillboardNotification({payload, onChangeState}) {
  const {uid} = payload;

  let response = {};

  try {
    await Sdk.dynamic.bridge(`cms/v1.0/user/data/uid/${uid}`, null, "DELETE");

    response = {
      "success": true,
    }
  } catch (e) {
    console.error(e);

    response = {
      "success": false,
    }
  }

  onChangeState(response);
}

async function getBillboardNotifications({payload, onChangeState}) {
  const {context_uid} = payload;

  const roles = Sdk.Api.authorization.user.roles;
  let hasAccess = false;
  if (roles) {
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].type === "ReviewAdmin" || roles[i].type === "ReviewContributor" || roles[i].type === "AdministratorGlobal") {
        hasAccess = true;
      }
    }
  }
  const temp = [];
  const api = await Sdk.dynamic.bridge(`cms/v1.0/user/data?origin=SystemMessage&context_type=CourseRegister&context_uid=${context_uid}&target_type=CourseRegister&target_uid=${context_uid}`);
  const result = api.result;

  for (var i = 0; i < result.length; i++) {
    temp.push({
      "uid": result[i].uid,
      "title": result[i].name,
      "content": result[i].description,
      "createdAt": result[i].created_at
    })
  }

  const resp = {
    data: temp,
    editable: hasAccess,
    canEdit: false, // TODO: Alterar quando o backend permitir essa operação
    canCreate: hasAccess,
    canDelete: false, // TODO: Alterar quando o backend permitir essa operação
    context_uid
  };

  onChangeState(resp);

  return resp;
}

async function personalInformationLoginConfirm({payload, onChangeState, executeRecaptcha}) {
  const {
    formValues = {
      login: "",
      answer: "",
    },
    name,
    inputLoginType,
  } = payload;
  let login = formValues.login;
  let response = {};

  try {
    const directory = await Sdk.dynamic.bridge(`auth/v1.0/directory/uid/${Sdk.Api.authorization.activeProject.directory.uid}`, null, "GET");

    if (directory) {
      const recoveryAccessDataConnector = directory.connectors.find(connector => connector.name === "RecoveryAccessData_1");
      const reCaptchaConnector = directory.connectors.find(connector => connector.name === "ReCaptcha_1");

      if (recoveryAccessDataConnector) {
        let connector_uid;
        let recaptchaToken;

        if (reCaptchaConnector) {
          connector_uid = reCaptchaConnector.uid;
          recaptchaToken = await executeRecaptcha();
        }

        const removeMask = (value) => {
          return value.replace(/\D+/g, '');
        }

        if ((typeof inputLoginType === "object" && inputLoginType.includes("cpf")) || inputLoginType === "cpf" ||
          (typeof inputLoginType === "object" && inputLoginType.includes("cnpj")) || inputLoginType === "cnpj") {
          login = removeMask(login);
          formValues.login = login;
        }

        const recaptchaQuery = connector_uid ? "&connector_uid=" + connector_uid + "&captcha_token=" + recaptchaToken : "";
        const resp = await Sdk.dynamic.bridge(`auth/v1.0/recovery/connector/uid/${recoveryAccessDataConnector.uid}/access/code?login=${login}${recaptchaQuery}`, null, "POST");

        response = {
          status: 200,
          connector: recoveryAccessDataConnector,
          code: resp.code[0] || "",
        }

      } else {
        response = {
          status: 404,
          errors: {
            [name]: "Configuração para recuperação de senha não encontrada",
          }
        }
      }
    }
  } catch (e) {
    console.log(e)
    if (e && "body" in e && "status" in e) {
      response = {
        status: e.status,
        errors: {
          [name]: "Usuário não encontrado",
        }
      };
    } else {
      response = {
        status: 500,
        errors: {
          [name]: "Ocorreu um erro inesperado. Tente novamente mais tarde.",
        }
      }
    }
  }

  onChangeState({
    status: response.status || 500,
    errors: response.errors || [],
    values: {
      ...formValues,
      connector: response.connector || undefined,
      code: response.code || undefined,
    },
  });
}

async function personalInformationQuestionConfirm({payload, onChangeState}) {
  const {
    formValues = {
      login: "",
      answer: "",
      connector: {},
      code: "",
    },
    name
  } = payload;

  try {
    if (!formValues.connector) {
      throw new Error("Recovery connector não encontrado");
    }

    var json = JSON.parse(formValues.connector.configuration);

    if (!("fields_available_recovery" in json)) {
      throw new Error("Nenhum campo de informação pessoal cadastrado no conector")
    }

    const propsNames = {
      "UserDetailBirthday": "birthday",
      "UserDetailEmail": "email",
      "UserDetailPhone": "phone",
      "UserDetailCpf": "cpf",
      "UserDetailRg": "rg",
      "UserDetailCnh": "cnh",
      "UserDetailFirstName": "first_name",
      "UserDetailLastName": "last_name",
    }

    const propName = json.fields_available_recovery[0];

    if (propName === "UserDetailBirthday") {
      formValues.answer = (formValues.answer || "").replace(/\D+/g, '').replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
    }

    const resp = await Sdk.dynamic.bridge(`auth/v1.0/recovery/connector/uid/${formValues.connector.uid}/access/state?login=${formValues.login}`, JSON.stringify({
      [propsNames[propName] || propName]: formValues.answer,
      "token": formValues.code,
    }), "POST");

    if (resp && "token" in resp) {
      onChangeState({
        status: 200,
        values: {
          ...formValues,
          token: resp.token,
        },
      });
      return;
    } else {
      onChangeState({
        status: 500,
        values: formValues,
      });
      return;
    }
  } catch (e) {
    if ("body" in e && "status" in e) {
      onChangeState({
        status: e.status,
        errors: {
          [name]: "Usuário não encontrado",
        }
      });
    } else {
      onChangeState({
        status: 500,
        errors: {
          [name]: "Ocorreu um erro inesperado. Tente novamente mais tarde.",
        }
      });
    }
    return;
  }
}

async function personalInformationNewPasswordConfirm({payload, onChangeState}) {
  const {
    formValues = {
      login: "",
      answer: "",
      connector: {},
      code: "",
      token: "",
      password: "",
      "confirm-password": "",
    },
  } = payload;

  try {
    await changeUserPassword({
      payload: {
        password: formValues.password,
        repassword: formValues["confirm-password"],
        token: formValues.token,
      },
      onChangeState,
    })
  } catch (e) {
    console.error(e);
  }

  onChangeState({
    status: 200,
    values: formValues,
  });
}

async function answerTermsPolicies({payload, onChangeState}) {
  const {
    term,
    index,
    accept,
  } = payload;

  try {
    if (accept) {
      const directory = await Sdk.dynamic.bridge(`auth/v1.0/directory/uid/${Sdk.Api.authorization.activeProject.directory.uid}`, null, "GET")

      if (directory) {
        if (Sdk.authorization.credential) {
          const termsPolicies = directory.connectors.find(connector => connector.type === "Policies_1") || {};

          await Sdk.dynamic.bridge(`auth/v1.0/policies/connector/uid/${termsPolicies.uid}/term/uid/${term.uid}/accepted`, {
            "accept": accept,
          }, "POST");
        }
      }
    } else {
      window.location = "/";
    }
  } catch (e) {
    console.error(e);
  }

  onChangeState({
    status: 200,
    values: payload,
  });
}

async function userContextCheck({payload, onChangeState, setConfig}) {
  let {
    login,
    loginType,
  } = payload;

  if (!login || login === "") {
    onChangeState({
      selectedContext: null,
      error: "Informe o login",
    });
    return;
  }

  try {
    const removeMask = (value) => {
      return value.replace(/\D+/g, '');
    }

    if ((typeof loginType === "object" && loginType.includes("cpf")) || loginType === "cpf" ||
      (typeof loginType === "object" && loginType.includes("cnpj")) || loginType === "cnpj") {
      login = removeMask(login);
    }

    let contexts = [];

    let resp = [];

    try {
      resp = await Sdk.dynamic.bridge(`auth/v1.0/directory/user/login/projects/available?ignore_user_context=true&login=${login}`, null, "GET");
    } catch (e) {
      console.error(e);
      resp = [];
    }

    const userContexts = [];

    if (resp && resp.length > 0) {
      try {
        await Promise.all(resp.map(async project => {
          try {
            const projectResp = await Sdk.dynamic.bridge(`auth/v1.0/project/uid/${project.uid}`, null, "GET");
            if (projectResp) {
              userContexts.push({
                ...projectResp,
                directory: project.directory,
              });
            }
          } catch (e) {
            console.error(e);
          }
        }));
      } catch (e) {
        console.error(e);
      }

      for (const project of userContexts) {
        try {
          const connector = (project.connectors || []).find(item => item.type === "Customization_1" && item.scope === "Project");

          if (connector && window.cordova) {
            const connectorInfo = await Sdk.dynamic.bridge(`auth/v1.1/connector/uid/${connector.uid}`, null, 'GET');

            const {
              configuration = {}
            } = connectorInfo;
            const {
              configs = []
            } = configuration;

            let configsParsed = {};
            const configElementsArr = ["header", "sidebar", "common", "dynamic_recovery_password", "first_access", "login", "course_tree", "users_group", "register", "tabed_categories"];
            for (const key of configElementsArr) {
              const config = configs.find(item => item.uid === key);
              if (config) {
                let elements = {};

                JSON.parse(config.value).forEach(item => {
                  elements[item.uid] = item.value;
                });

                configsParsed = {...configsParsed, [config.uid]: elements};
              }
            }

            project.config = configsParsed;
            project.logo =  configsParsed.header.logotype;
            project.background_color =  configsParsed.header.background;
            project.font_color =  configsParsed.common.secondary_color;
          }

          contexts.push(project);
        } catch (e) {
          console.error(e);
        }
      }

      if (contexts.length === 1) {
        let selectedContext = contexts[0] || null;

        try {
          if ("config" in selectedContext && selectedContext.config) {
            setConfig({
              config: selectedContext.config
            });
            let date = new Date();
            date.setFullYear(date.getFullYear() + 1000);
            localStorage.setItem("rdp-app-config", JSON.stringify({
              config: {
                config: selectedContext.config
              },
              expires: date
            }));
          }
        } catch (e) {
          console.error(e);
        }
        try {
          await Sdk.initializeFromProject(selectedContext.uid, "DirectRegister_1");
          Sdk.authorization.setActiveProject(selectedContext);
          Sdk.authorization.activeProjectUid = selectedContext.uid;
        } catch (e) {
          console.error(e);
        }
        onChangeState({
          selectedContext: contexts[0],
          contexts: null,
          error: null,
        });
      } else {
        onChangeState({
          selectedContext: null,
          contexts,
          error: null,
        });
      }
    } else {
      onChangeState({
        selectedContext: {},
        contexts: null,
        // error: "Nenhum usuário encontrado.",
        error: null
      });
    }
  } catch (e) {
    console.error(e);
    onChangeState({
      selectedContext: null,
      contexts: null,
      error: "Erro ao buscar contexto do usuário.",
    });
  }
}

async function setContext({payload, onChangeState, setConfig}) {
  const {selectedContext} = payload;
  const {config} = selectedContext || {};

  const setDefaultConfig = async () => {
    if (window.cordova) {
      try {
        localStorage.removeItem("rdp-app-config");
        const config = await getConfigByProject("degustacao.euconquisto.com");
  
        if (config) {
          setConfig(config);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  if (selectedContext !== null) {
    try {
      await Sdk.initializeFromProject(selectedContext.uid, "DirectRegister_1");
      Sdk.authorization.setActiveProject(selectedContext);
      Sdk.authorization.activeProjectUid = selectedContext.uid;
    } catch (e) {
      console.error(e);
    }

    onChangeState({
      selectedContext,
    });

    if (window.cordova) {
      if (config) {
        setConfig({
          config
        });
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1000);
        localStorage.setItem("rdp-app-config", JSON.stringify({
          config: {
            config
          },
          expires: date
        }));
      } else {
        setDefaultConfig();
      }
    }
  } else {
   setDefaultConfig();
    onChangeState({
      selectedContext: null,
    });
  }
}

export default {
  registerContent,
  register,
  silentRegister,
  signin,
  silentSignIn,
  logout,
  recoveryPassword,
  recoveryUserPassword,
  changePassword,
  changeLoggedPassword,
  changeUserPassword,
  toggleReviewFlows,
  availableToReview,
  openReview,
  acceptReview,
  rejectReview,
  updateUser,
  readNotifications,
  saveFavorite,
  removeFavorite,
  getActivityFavorites,
  getActivityForumMessages,
  sendHelpDeskZTicket,
  getRank,
  updateBillboardNotification,
  deleteBillboardNotification,
  createBillboardNotification,
  getBillboardNotifications,
  personalInformationLoginConfirm,
  personalInformationQuestionConfirm,
  personalInformationNewPasswordConfirm,
  answerTermsPolicies,
  checkPendingSubscriptions,
  registerIntoOffer,
  userContextCheck,
  setContext
}
