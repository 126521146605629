import React from 'react';
import './Loading.css'

export const Loading = () => {
    return(
        <div class="loading-wrapper">
        <div class="custom-loader"></div>
        </div>
    )
}

export default Loading