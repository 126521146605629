import React, {useState, useEffect} from 'react';

import {HashRouter as Router, Redirect} from 'react-router-dom';
import GlobalStyle from './styles';

import Routes from './routes';

import {Provider} from 'react-redux';
import {store} from './store';

// import { projectUid, version, projectKey } from '~/config';

// import Sdk from 'api.digitalpages.module.sdk.api';
//import SdkRequest from 'api.digitalpages.module.request.api';

import styled, {ThemeProvider} from 'styled-components';
import theme from './theme';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import GoogleRecaptchaBadge from "~/components/google-recaptcha-badge/google-recaptcha-badge";
import {AppContext} from "~/contexts/app-context";
import Sdk from "api.digitalpages.module.sdk.api";
import {ODFProvider} from "ecc-data-fetch-sdk";

const WrapperLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

function App() {
	const [ready,] = useState(true);
	const [showReCaptchaBadge, setShowReCaptchaBadge] = useState(false);
	const [reCaptchaKey, setReCaptchaKey] = useState("");
	const [online, setOnline] = useState(true);

	const toggleReCaptchaBadge = (show) => {
		setShowReCaptchaBadge(show);
	}
	useEffect(() => {
		window.addEventListener('scroll', function () {
			if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight)) {
				window.dispatchEvent(new Event("@rdpplayer.event(scrolltobottom)"))
			}
		});

		window.addEventListener('online',  () => setOnline(true));
		window.addEventListener('offline', () => setOnline(false));

		const loadAppReCaptchaKey = async () => {
			if (Sdk.Api.authorization.activeProject && Sdk.Api.authorization.activeProject.directory) {
				const directory = await Sdk.dynamic.bridge(`auth/v1.0/directory/uid/${Sdk.Api.authorization.activeProject.directory.uid}`, null, "GET");
				if (directory) {
					const reCaptchaConnector = directory.connectors.find(connector => connector.name === "ReCaptcha_1");
					if (reCaptchaConnector && reCaptchaConnector.configuration.public_key) {
						setReCaptchaKey(reCaptchaConnector.configuration.public_key);
					}
				}
			}
		}

		//loadAppReCaptchaKey();

		return () => {
			window.removeEventListener('scroll', function () {
				if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight)) {
					window.dispatchEvent(new Event("@rdpplayer.event(scrolltobottom)"))
				}
			});
			window.removeEventListener('online',  () => setOnline(true));
			window.removeEventListener('offline', () => setOnline(false));
		}
	}, []);

	return (
		<Provider store={store}>
			<AppContext.Provider value={{
				showReCaptchaBadge,
				toggleReCaptchaBadge,
				online,
			}}>
				<GoogleReCaptchaProvider
					reCaptchaKey={reCaptchaKey}
					container={{
						element: "app-recaptcha-badge-container",
						parameters: {
							badge: 'bottomright',
						}
					}}
				>
					<Router>
						<ThemeProvider theme={theme}>
							<div id="root" className="rdp-rdpplayer">
								<GlobalStyle/>
								<ODFProvider>
									{ready && <Routes/>}
								</ODFProvider>
							</div>
						</ThemeProvider>
					</Router>
					<GoogleRecaptchaBadge show={showReCaptchaBadge}/>
				</GoogleReCaptchaProvider>
			</AppContext.Provider>
		</Provider>
	);
}

export default App;
