function inApp () {
  if (window.cordova || localStorage.getItem("iammobile") !== null) {
    return true;
  } else {
    return false;
  };
};

function isOnline () {
  if (inApp()) {
    // window.CordovaExec(function(online) {
    //   return online === "true";
    // }, "ShelfPlugin", "RegisterConnectionStatus");
    window.CordovaExec(function(response) {
      var result = JSON.parse(response);
      return result.status;
    }, "UnifiedApiPlugin", "Process", [{ name: "HandleNetwork" }]);
  } else {
    return true;
  }
};

async function resolveRequest(params) {
  let resp = {"result": []};
  if (isOnline()) {
    resp = await params.request;
    localStorage.setItem(`ecc-localstorage-${params.db_name}-${window.location.origin}`, JSON.stringify(resp));
  } else {
    if (localStorage.getItem(`ecc-localstorage-${params.db_name}-${window.location.origin}`) !== null) {
      resp = JSON.parse(localStorage.getItem(`ecc-localstorage-${params.db_name}-${window.location.origin}`))
    }
  }

  return resp;
}

function getMobileOperatingSystem () {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  };

  if (/android/i.test(userAgent)) {
    return "Android";
  };

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  };

  return "unknown";
}

export default {
  inApp,
  isOnline,
  resolveRequest,
  getMobileOperatingSystem
}